import * as React from "react";
import Layout from "../components/Layout";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import useFormattedMsg from "../hooks/useFormattedMsg";
import ContactInformation from "../components/ContactInformation/ContactInformation";

const ImprintPage = () => {
  const title = useFormattedMsg("imprint.title");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Layout>
        <Container style={{ "padding-top": "2rem", "padding-bottom": "2rem" }}>
          <h1 style={{ "margin-bottom": "2rem" }}>
            <FormattedMessage id="common.company_full_name" />
          </h1>
          <p>
            <ContactInformation />
          </p>
          <p>
            <FormattedMessage id="imprint.field_of_activity" />:{" "}
            <FormattedMessage id="imprint.field_of_activity_text" />
          </p>
          <p>
            <FormattedMessage id="common.ceo" />:{" "}
            <FormattedMessage id="common.ceo_full_name" />
            <br />
            <FormattedMessage id="imprint.vat_number" />:{" "}
            <FormattedMessage id="imprint.vat_number_value" />
            <br />
            <FormattedMessage id="imprint.company_registration_number" />:{" "}
            <FormattedMessage id="imprint.company_registration_number_value" />
            <br />
            <FormattedMessage id="imprint.company_court" />:{" "}
            <FormattedMessage id="imprint.company_court_value" />
            <br />
            <FormattedMessage id="imprint.gln" />:{" "}
            <FormattedMessage id="imprint.gln_value" />
            <br />
          </p>
        </Container>
      </Layout>
    </>
  );
};

export default ImprintPage;
